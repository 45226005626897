
  import Vue from "vue";
  import PwaCountdownMessage from "@/components/common/PwaCountdownMessage.vue"
  import { COMPETITION_STATUS_ID } from '@/utils/competition';
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import { getLastTimeRanked } from '@/utils/common';

  export default Vue.extend({
    name: "pwa-ranked-header",
    methods: {getLastTimeRanked},
    computed: {
      COMPETITION_STATUS_ID() {
        return COMPETITION_STATUS_ID
      }
    },
    props: {
      competition: {
        type: Object as any,
        required: true
      },
      competitionsFreeInscriptionsId: {
        type: Number,
        required: false,
        default: null,
      },
    },
    components: {
      PwaCountdownMessage,
      PwaIcon
    },
  });
