
  import Vue from 'vue';
  import PwaRanked from "@/components/competitions/PwaRanked.vue";
  import {ROUTES} from "@/router/routes";
  import pageMixin from "@/mixins/page";
  import {BreadcrumbItems, BreadcrumbItemType} from "@/utils/breadcrumb";
  import {EVENTS} from "@/utils/events";

  export default Vue.extend({
    name: "Ranked",
    mixins: [pageMixin],
    components: {
      PwaRanked,
    },
    data() {
      return {
        competition: {} as any,
        classification: [],
        userClassification: {} as any,
        gfyScore: {},
        competitionsFreeInscriptionsId: null,
        autoRefreshCompetitionPageTimeout: null,
      }
    },
    mounted(): void {
      this.getRankedPage(true);
      this.$root.$on(EVENTS.RELOAD_RANKED_PAGE, () => this.getRankedPage(true));
      this.$root.$on(EVENTS.ON_PLAY_GAME_MODAL_CLOSE, () => this.getRankedPage(true));
    },
    beforeDestroy(): void {
      this.$root.$off(EVENTS.RELOAD_RANKED_PAGE);
      this.$root.$off(EVENTS.ON_PLAY_GAME_MODAL_CLOSE);
      this.clearAutoRefreshCompetitionPageTimeout();
    },
    computed: {
      competitionId(): number {
        return parseInt(this.$route.params.competitionId);
      },
      breadcrumb(): BreadcrumbItemType[] {
        return [
          BreadcrumbItems.lobby,
          {
            text: this.competition.game_name || '-',
            to: this.competition.game_alias ? {name: ROUTES.minigame.name, params: {lang: this.routeLang, gameAlias: this.competition.game_alias}} : null,
          },
          {text: this.competition.region || '-'},
        ];
      }
    },
    methods: {
      async getRankedPage(showLoader = false) {
        if(showLoader) {
          this.showLoader(true);
        }
        try {
          const {data} = await this.$http.page.getRankedPage(this.competitionId, {});
          this.competition = data.data.competition || {};
          this.classification = data.data.classification || {};
          this.userClassification = data.data.user_classification || {};
          this.gfyScore = data.data.gfy_score || {};
          this.competitionsFreeInscriptionsId = data.data.competitions_free_inscriptions_id || null;
        } catch (e) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      clearAutoRefreshCompetitionPageTimeout(): void {
        if (this.autoRefreshCompetitionPageTimeout) {
          clearTimeout(this.autoRefreshCompetitionPageTimeout);
          this.autoRefreshCompetitionPageTimeout = null;
        }
      },
    },
    watch: {
      competitionId() {
        this.clearAutoRefreshCompetitionPageTimeout();
        this.getRankedPage(true);
      }
    }
  });
