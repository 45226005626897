
  import Vue, {PropType} from 'vue';
  import PwaPrice from "@/components/common/PwaPrice.vue";
  import PwaCountdownMessage from "@/components/common/PwaCountdownMessage.vue";
  import PwaCountRoller from '@/components/common/PwaCountRoller.vue';
  import PwaCompetitionRules from "@/components/competitions/PwaCompetitionRules.vue";
  import PwaRankedHeader from "@/components/competitions/PwaRankedHeader.vue";
  import PwaInscriptionNoMoney from "@/components/modals/competitions/PwaInscriptionNoMoney.vue";
  import PwaRankedClassification from "@/components/competitions/PwaRankedClassification.vue";
  import {UserActionTypes} from "@/store/modules/user/actions";
  import {
    COMPETITION_STATUS_ID,
    getPlatformIcon, RegisterCompetitionErrorKey
  } from "@/utils/competition";
  import {ROUTES} from "@/router/routes";
  import {getLastTimeRanked} from '@/utils/common';
  import { MODAL_IDS } from "@/utils/modal";
  import PwaPlayGame from '@/components/competitions/PwaPlayGame.vue';
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import PwaCurrency from "@/components/common/PwaCurrency.vue";

  export default Vue.extend({
    name: "pwa-ranked",
    components: {
      PwaCompetitionRules,
      PwaRankedHeader,
      PwaPrice,
      PwaCountdownMessage,
      PwaCountRoller,
      PwaRankedClassification,
      PwaIcon,
      PwaCurrency,
    },
    props: {
      competition: {
        type: Object,
        required: true
      },
      classification: {
        type: Array,
        required: true
      },
      userClassification: {
        type: Object,
        required: false
      },
      gfyScore: {
        type: [Object, Array],
        required: true
      },
      userAward: {
        type: String,
        required: false,
        default: null,
      },
      onReloadCompetitionData: {
        type: Function as PropType<() => void>,
        required: true,
      },
      competitionsFreeInscriptionsId: {
        type: Number,
        required: false,
        default: null,
      }
    },
    data() {
      return {
        termsAndConditionsAccepted: false,
        hasTimeLeft: true,
        forceDisabledButton: false,
        autoRefreshInterval: null,
        useCompetitionFreeInscription: 0,
      }
    },
    mounted(): void {
      this.restartAutoRefreshInterval();
    },
    beforeDestroy(): void {
      this.clearAutoRefreshInterval();
    },
    computed: {
      COMPETITION_STATUS_ID() {
        return COMPETITION_STATUS_ID
      },
      competitionId(): number {
        return this.competition ? parseInt(this.competition.id) : null;
      },
      competitionStatusId(): number {
        return this.competition.status_id ? this.competition.status_id : 0;
      },
      isCompetitionCancelled(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.CANCELLED;
      },
      isCompetitionStarted(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.STARTED;
      },
      isCompetitionFinished(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.FINISHED;
      }
    },
    methods: {
      getLastTimeRanked,
      getPlatformIcon,
      async playUserRanked() {
        this.forceDisabledButton = true;
        this.showLoader(true);
        try {
          const {data} = await this.$http.user.postUserRanked(this.userId, this.competitionId, {use_competition_free_inscription: this.useCompetitionFreeInscription});
          const resultId = data.data;
          this.onRegisterUserRankedSuccess(resultId);
        } catch ({response}) {
          const errorData = response?.data || {};
          const key = errorData?.key;
          const {NO_MONEY} = RegisterCompetitionErrorKey;
          if (key === NO_MONEY) {
            this.showPopupNoMoney(this.competition.cost_inscription);
          } else {
            const errorMessage = errorData?.message || errorData?.errors || this.$t('140');
            this.showToastError(this.$t('139'), errorMessage);
          }
        } finally {
          this.forceDisabledButton = false;
          this.showLoader(false);
        }
      },
      onRegisterUserRankedSuccess(resultId: number) {
        this.$store.dispatch(UserActionTypes.UPDATE_USER_WALLET);
        const gameAlias = this.competition.game_alias;
        if(this.isDesktop) {
          const message = this.$createElement(PwaPlayGame, {
            props: {
              isModal: true,
              gameAlias: gameAlias,
              competitionId: this.competition.id,
              resultId: resultId,
            },
          });
          const title = this.$t('549');
          this.showModal({
            id: MODAL_IDS.MODAL_PLAY_GAME,
            title,
            message,
            hideCancel: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
            dialogClass: 'game-container',
            headerClass: 'title-centered',
            contentClass: 'create-match-background',
            footerClass: 'buttons-hidden',
          });
        } else {
          const params: Record<string, string> = {
            gameAlias: gameAlias,
            competitionId: this.competition.id,
            resultId: resultId.toString(),
          };
          this.$router.push({name: ROUTES.playGame.name, params: {lang: this.routeLang, ...params}});
        }
      },
      onRegisterPlayClick(useCompetitionFreeInscription = 0) {
        if (this.termsAndConditionsAccepted) {
          if (!this.isLoggedIn) {
            this.goToSignup();
          } else {
            // Comprobamos si tiene saldo
            if (!useCompetitionFreeInscription && this.walletQuantity < this.competition.cost_inscription) {
              this.showPopupNoMoney(this.competition.cost_inscription);
            } else {
              this.useCompetitionFreeInscription = useCompetitionFreeInscription;
              this.playUserRanked();
            }
          }
        } else {
          this.showToastError(this.$t('139'), this.$t('151'));
        }
      },
      showPopupNoMoney(costInscription: number) {
        const message = this.$createElement(PwaInscriptionNoMoney, {
          props: {
            create: false,
            costInscription,
          },
        });
        this.showModal({
          id: 'modal-no-money',
          message,
          showOnRoot: true,
          contentClass: {'no-money': true, 'is-desktop': this.isDesktop, 'hide-footer': true},
        });
      },
      clearAutoRefreshInterval(): void {
        if (this.autoRefreshInterval) {
          clearInterval(this.autoRefreshInterval);
          this.autoRefreshInterval = null;
        }
      },
      initAutoRefreshInterval(): void {
        this.clearAutoRefreshInterval();
        this.autoRefreshInterval = setInterval(() => {
            this.onReloadCompetitionData();
        }, 30000);
      },
      restartAutoRefreshInterval(): void {
        if (!this.isCompetitionFinished && !this.isCompetitionCancelled) {
          this.initAutoRefreshInterval();
        } else {
          this.clearAutoRefreshInterval();
        }
      }
    },
    watch: {
      competitionId() {
        this.restartAutoRefreshInterval();
      }
    }
  });
