
import Vue from "vue";
import PwaIcon from "@/components/common/PwaIcon.vue";
import PwaPrice from '@/components/common/PwaPrice.vue';
import { ROUTES } from '@/router/routes';
import { isObjectEmpty, ordinal } from '@/utils/common';

export default Vue.extend({
  name: "pwa-ranked-classification",
  components: {
    PwaIcon,
    PwaPrice,
  },
  props: {
    classification: {
      type: Array,
      required: true,
    },
    userClassification: {
      type: Object,
      required: false,
    },
    awards: {
      type: Array,
      required: true,
    }
  },
  methods: {
    isObjectEmpty,
    goToUserProfile(username: string) {
      if (username) {
        this.$router.push({name: ROUTES.profile.name, params: {lang: this.routeLang, username}});
      }
    },
    ordinal(num: number) {
      return ordinal(num);
    },
  }
});
